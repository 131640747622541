import {
  corePhasesManager,
  modes,
  playersManager,
  requestManager,
  type TrainingDataFromResultsRequest,
  trainingManager,
  gameStats
} from '@powerplay/core-minigames'
import type { SaveResultsDataToSend } from './types'
import { tutorialFlow } from './modes/tutorial/TutorialFlow'
import { player } from './entities/athlete/player'
import { trainingResultsState } from '@/stores'
import { waitingState } from '@powerplay/core-minigames-ui-ssm'

/**
 * Trieda pre koniec discipliny
 */
export class EndManager {

  /** ci uz bol result poslany alebo nie */
  private resultSent = false

  /** ci sa skoncilo predcasne */
  public prematureEnded = false

  /** Kolko bolo zapisanych resultov */
  public resultsCount = 0

  /**
   * Poslanie requestu pre konecne logovanie
   * @param uuid - Hracove UUID
   */
  public sendLogEnd(playerUuid: string): void {

    // ak uz mame nastavene, tak uz viac nenastavujeme, prip nastavujeme az na konci
    if (
      Object.keys(gameStats.getDisciplineDataToLog()).length > 0 ||
      corePhasesManager.disciplineActualAttempt < corePhasesManager.disciplineAttemptsCount
    ) return

    // pri logoch chceme aktualne hodnoty a nie najlepsie
    const pointsArr = (playersManager.players[0].resultsArr?.map((object) => object.main) ?? [0, 0, 0, 0, 0, 0])
      .splice(0, this.resultsCount)
    const points10xArr = playersManager.players[0].resultsArr?.map((object) => object.third ?? 0) ?? [0, 0, 0, 0, 0, 0]

    // zaznamename nejake info pre logy
    gameStats.setDisciplineDataToLog({
      points: pointsArr,
      points10x: points10xArr.splice(0, this.resultsCount),
      pointsTotal: pointsArr.reduce((acc, curVal) => acc + Number(curVal), 0),
      dnf: playersManager.dnf,
      playerPosition: playersManager.getPlayerActualPosition(playerUuid),
      trainingTasks: modes.isTrainingMode() ?
        trainingManager.getTrainingTasks().map(task => task.value) :
        [],
      tutorialData: modes.isTutorial() ? this.getTutorialLogs() : []
    })

    console.log('LOG to send', gameStats.getDisciplineDataToLog())

  }

  /**
   * ziskame objekt tutorialovych logov
   * @returns - objekt tutorialovych logov
   */
  private getTutorialLogs(): (number | boolean)[] {

    /**
     * wasOutOfTime - Ci hrac dostal hlasku o tom, ze mu vyprsal cas
     * result - Pocet bodov, ktore hrac dosiahol (kedze to moze byt viac ako 30)
     * attempts - Pocet sipov, ktore hrac potreboval na splnenie zadania
     * exited - Počet predcasne ukoncenych tutorialov
     */
    const playerScore = Number(playersManager.getStandings().find(standing => {

      return standing.playable

    })?.result) || 0

    return [
      tutorialFlow.wasOutOfTime, // wasOutOfTime
      playerScore, // result
      tutorialFlow.attemptToHit30, // attempts
      this.prematureEnded // exited
    ]

  }

  /**
   * Vybratie dat a poslanie do funkcie z core-minigames
   */
  public sendSaveResult(): void {

    // ak uz bol result poslany, neposielame ho znova
    if (this.resultSent) return

    // TODO TEMP - zatial takto, ked bude hotovy tutorial, tak sa to bude posielat tam
    requestManager.sendTutorialRequest()

    if (modes.isTutorial()) return

    // musime vybrat to lepsie umiestnenie a iba to budeme riesit (kedze su zoradeni, zoberieme prveho)
    const playerStanding = playersManager.getStandings().filter(standing => standing.uuid === player.uuid)
    const results = playerStanding[0].resultsFull
    const pointsArr = results ? results.map((object) => Number(object.main)) : [0, 0, 0, 0, 0, 0]

    const data: SaveResultsDataToSend = {
      points: pointsArr,
      points10x: results ? results.map((object) => Number(object.third)) : [0, 0, 0, 0, 0, 0],
      pointsTotal: results ? pointsArr.reduce((acc, curVal) => acc + Number(curVal), 0) : 0,
      positions: playersManager.getPlayersPositions(),
      dnf: playersManager.dnf,
    }

    if (modes.isTrainingMode()) {

      data.trainingResults = trainingManager.getTrainingTasks().map(task => task.value)

    } else if (corePhasesManager.disciplineActualAttempt < corePhasesManager.disciplineAttemptsCount) {

      // ked este nie je posledny pokus, tak neposielame save results, ale v treningu ano
      waitingState().isWaiting = false

      return

    }

    this.resultSent = true

    console.log('data to send', data)

    requestManager.sendSaveResultsRequest(
      (dataCallback: TrainingDataFromResultsRequest | unknown) => {

        console.log('saveRequest Successful')

        if (modes.isTrainingMode()) {

          trainingResultsState().$patch({
            data: dataCallback as TrainingDataFromResultsRequest,
            bestScore: trainingManager.bestScore,
            dataSet: true
          })

        }

      },
      JSON.stringify(data)
    )

  }

  /**
   * Reset result
   */
  public reset(): void {

    this.resultSent = false
    this.resultsCount = 0

  }

}

export const endManager = new EndManager()
